export default {
   "19":{
      "name":{
         "TC":"大埔",
         "EN":"Tai Po Sports Centre"
      },
      "region":"NTE",
      "district":"TP"
   },
   "23":{
      "name":{
         "TC":"林士德",
         "EN":"Osman Ramju Sadick Memorial Sports Centre"
      },
      "district":"KWT",
      "region":"NTW"
   },
   "25":{
      "name":{
         "TC":"屯門蝴蝶灣",
         "EN":"JC Tuen Mun Butterfly Beach Sports Centre"
      },
      "region":"NTW",
      "district":"TM"
   },
   "26":{
      "name":{
         "TC":"友愛",
         "EN":"Yau Oi Sports Centre"
      },
      "region":"NTW",
      "district":"TM"
   },
   "35":{
      "name":{
         "TC":"北葵鄧肇堅",
         "EN":"North Kwai Chung Tang Shiu Kin Sports Centre"
      },
      "district":"KWT",
      "region":"NTW"
   },
   "36":{
      "name":{
         "TC":"長發",
         "EN":"Cheung Fat Sports Centre"
      },
      "district":"KWT",
      "region":"NTW"
   },
   "37":{
      "name":{
         "TC":"楓樹窩",
         "EN":"Fung Shue Wo Sports Centre"
      },
      "district":"KWT",
      "region":"NTW"
   },
   "38":{
      "name":{
         "TC":"大窩口",
         "EN":"Tai Wo Hau Sports Centre"
      },
      "district":"KWT",
      "region":"NTW"
   },
   "40":{
      "name":{
         "TC":"青衣",
         "EN":"Tsing Yi Sports Centre"
      },
      "district":"KWT",
      "region":"NTW"
   },
   "42":{
      "name":{
         "TC":"蕙荃",
         "EN":"Wai Tsuen Sports Centre"
      },
      "region":"NTW",
      "district":"TW"
   },
   "43":{
      "name":{
         "TC":"荃景圍",
         "EN":"Tsuen King Circuit Sports Centre"
      },
      "region":"NTW",
      "district":"TW"
   },
   "49":{
      "name":{
         "TC":"天平",
         "EN":"Tin Ping Sports Centre"
      },
      "district":"N",
      "region":"NTE"
   },
   "53":{
      "name":{
         "TC":"龍琛路",
         "EN":"Lung Sum Avenue Sports Centre"
      },
      "district":"N",
      "region":"NTE"
   },
   "54":{
      "name":{
         "TC":"和興",
         "EN":"Wo Hing Sports Centre"
      },
      "district":"N",
      "region":"NTE"
   },
   "55":{
      "name":{
         "TC":"富善",
         "EN":"Fu Shin Sports Centre"
      },
      "region":"NTE",
      "district":"TP"
   },
   "56":{
      "name":{
         "TC":"太和",
         "EN":"Tai Wo Sports Centre"
      },
      "region":"NTE",
      "district":"TP"
   },
   "57":{
      "name":{
         "TC":"富亨",
         "EN":"Fu Heng Sports Centre"
      },
      "region":"NTE",
      "district":"TP"
   },
   "60":{
      "name":{
         "TC":"寶林",
         "EN":"Po Lam Sports Centre"
      },
      "region":"NTE",
      "district":"SK"
   },
   "61":{
      "name":{
         "TC":"翠林",
         "EN":"Tsui Lam Sports Centre"
      },
      "region":"NTE",
      "district":"SK"
   },
   "66":{
      "name":{
         "TC":"荔景",
         "EN":"Lai King Sports Centre"
      },
      "district":"KWT",
      "region":"NTW"
   },
   "67":{
      "name":{
         "TC":"恆安",
         "EN":"Heng On Sports Centre"
      },
      "region":"NTE",
      "district":"ST"
   },
   "69":{
      "name":{
         "TC":"顯徑",
         "EN":"Hin Keng Sports Centre"
      },
      "region":"NTE",
      "district":"ST"
   },
   "72":{
      "name":{
         "TC":"大興",
         "EN":"Tai Hing Sports Centre"
      },
      "region":"NTW",
      "district":"TM"
   },
   "73":{
      "name":{
         "TC":"良田",
         "EN":"Leung Tin Sports Centre"
      },
      "region":"NTW",
      "district":"TM"
   },
   "76":{
      "name":{
         "TC":"鳳琴街",
         "EN":"Fung Kam Street Sports Centre"
      },
      "region":"NTW",
      "district":"YL"
   },
   "77":{
      "name":{
         "TC":"朗屏",
         "EN":"Long Ping Sports Centre"
      },
      "region":"NTW",
      "district":"YL"
   },
   "82":{
      "name":{
         "TC":"海傍街",
         "EN":"Praya Street Sports Centre"
      },
      "district":"IS",
      "region":"NTW"
   },
   "84":{
      "name":{
         "TC":"長洲",
         "EN":"Cheung Chau Sports Centre"
      },
      "district":"IS",
      "region":"NTW"
   },
   "87":{
      "name":{
         "TC":"天水圍",
         "EN":"Tin Shui Wai Sports Centre"
      },
      "region":"NTW",
      "district":"YL"
   },
   "91":{
      "name":{
         "TC":"美林",
         "EN":"Mei Lam Sports Centre"
      },
      "region":"NTE",
      "district":"ST"
   },
   "92":{
      "name":{
         "TC":"源禾路",
         "EN":"Yuen Wo Road Sports Centre"
      },
      "region":"NTE",
      "district":"ST"
   },
   "94":{
      "name":{
         "TC":"將軍澳",
         "EN":"Tseung Kwan O Sports Centre"
      },
      "region":"NTE",
      "district":"SK"
   },
   "95":{
      "name":{
         "TC":"楊屋道",
         "EN":"Yeung Uk Road Sports Centre"
      },
      "region":"NTW",
      "district":"TW"
   },
   "96":{
      "name":{
         "TC":"荃灣西約",
         "EN":"Tsuen Wan West Sports Centre"
      },
      "region":"NTW",
      "district":"TW"
   },
   "200":{
      "name":{
         "TC":"鴨脷洲",
         "EN":"Ap Lei Chau Sports Centre"
      },
      "region":"HK",
      "district":"SN"
   },
   "204":{
      "name":{
         "TC":"香港公園",
         "EN":"Hong Kong Park Sports Centre"
      },
      "district":"CW",
      "region":"HK"
   },
   "206":{
      "name":{
         "TC":"士美非路",
         "EN":"Smithfield Sports Centre"
      },
      "district":"CW",
      "region":"HK"
   },
   "207":{
      "name":{
         "TC":"石塘咀",
         "EN":"Shek Tong Tsui Sports Centre"
      },
      "district":"CW",
      "region":"HK"
   },
   "208":{
      "name":{
         "TC":"上環",
         "EN":"Sheung Wan Sports Centre"
      },
      "district":"CW",
      "region":"HK"
   },
   "209":{
      "name":{
         "TC":"中山紀念公園",
         "EN":"Sun Yat Sen Memorial Park Sports Centre"
      },
      "district":"CW",
      "region":"HK"
   },
   "212":{
      "name":{
         "TC":"柴灣",
         "EN":"Chai Wan Sports Centre"
      },
      "district":"EN",
      "region":"HK"
   },
   "213":{
      "name":{
         "TC":"渣華道",
         "EN":"Java Road Sports Centre"
      },
      "district":"EN",
      "region":"HK"
   },
   "214":{
      "name":{
         "TC":"鰂魚涌",
         "EN":"Quarry Bay Sports Centre"
      },
      "district":"EN",
      "region":"HK"
   },
   "217":{
      "name":{
         "TC":"港島東",
         "EN":"Island East Sports Centre"
      },
      "district":"EN",
      "region":"HK"
   },
   "219":{
      "name":{
         "TC":"西灣河",
         "EN":"Sai Wan Ho Sports Centre"
      },
      "district":"EN",
      "region":"HK"
   },
   "223":{
      "name":{
         "TC":"佛光街",
         "EN":"Fat Kwong Street Sports Centre"
      },
      "district":"KC",
      "region":"KLN"
   },
   "224":{
      "name":{
         "TC":"紅磡市政",
         "EN":"Hung Hom Municipal Services Building Sports Centre"
      },
      "district":"KC",
      "region":"KLN"
   },
   "228":{
      "name":{
         "TC":"九龍城",
         "EN":"Kowloon City Sports Centre"
      },
      "district":"KC",
      "region":"KLN"
   },
   "233":{
      "name":{
         "TC":"土瓜灣",
         "EN":"To Kwa Wan Sports Centre"
      },
      "district":"KC",
      "region":"KLN"
   },
   "239":{
      "name":{
         "TC":"界限街一館",
         "EN":"Boundary Street Sports Centre - Arena (Centre 1)"
      },
      "region":"KLN",
      "district":"YTM"
   },
   "240":{
      "name":{
         "TC":"界限街二館",
         "EN":"Boundary Street Sports Centre - Arena (Centre 2)"
      },
      "region":"KLN",
      "district":"YTM"
   },
   "241":{
      "name":{
         "TC":"花園街",
         "EN":"Fa Yuen Street Sports Centre"
      },
      "region":"KLN",
      "district":"YTM"
   },
   "244":{
      "name":{
         "TC":"彩虹道中心",
         "EN":"Choi Hung Road Badminton Centre"
      },
      "region":"KLN",
      "district":"WTS"
   },
   "245":{
      "name":{
         "TC":"彩虹道",
         "EN":"Choi Hung Road Sports Centre"
      },
      "region":"KLN",
      "district":"WTS"
   },
   "246":{
      "name":{
         "TC":"竹園",
         "EN":"Chuk Yuen Sports Centre"
      },
      "region":"KLN",
      "district":"WTS"
   },
   "248":{
      "name":{
         "TC":"東啟德",
         "EN":"Kai Tak East Sports Centre"
      },
      "region":"KLN",
      "district":"WTS"
   },
   "254":{
      "name":{
         "TC":"摩士公園",
         "EN":"Morse Park Sports Centre"
      },
      "region":"KLN",
      "district":"WTS"
   },
   "256":{
      "name":{
         "TC":"牛池灣",
         "EN":"Ngau Chi Wan Sports Centre"
      },
      "region":"KLN",
      "district":"WTS"
   },
   "257":{
      "name":{
         "TC":"蒲崗村道",
         "EN":"Po Kong Village Road Sports Centre"
      },
      "region":"KLN",
      "district":"WTS"
   },
   "259":{
      "name":{
         "TC":"長沙灣",
         "EN":"Cheung Sha Wan Sports Centre"
      },
      "region":"KLN",
      "district":"SSP"
   },
   "261":{
      "name":{
         "TC":"荔枝角公園",
         "EN":"Lai Chi Kok Park Sports Centre"
      },
      "region":"KLN",
      "district":"SSP"
   },
   "263":{
      "name":{
         "TC":"北河街",
         "EN":"Pei Ho Street Sports Centre"
      },
      "region":"KLN",
      "district":"SSP"
   },
   "264":{
      "name":{
         "TC":"保安道",
         "EN":"Po On Road Sports Centre"
      },
      "region":"KLN",
      "district":"SSP"
   },
   "266":{
      "name":{
         "TC":"石硤尾公園",
         "EN":"Shek Kip Mei Park Sports Centre"
      },
      "region":"KLN",
      "district":"SSP"
   },
   "273":{
      "name":{
         "TC":"黃竹坑",
         "EN":"Wong Chuk Hang Sports Centre"
      },
      "region":"HK",
      "district":"SN"
   },
   "274":{
      "name":{
         "TC":"香港仔",
         "EN":"Aberdeen Sports Centre"
      },
      "region":"HK",
      "district":"SN"
   },
   "278":{
      "name":{
         "TC":"漁光道",
         "EN":"Yue Kwong Road Sports Centre"
      },
      "region":"HK",
      "district":"SN"
   },
   "279":{
      "name":{
         "TC":"振華道",
         "EN":"Chun Wah Road Sports Centre"
      },
      "region":"KLN",
      "district":"KT"
   },
   "281":{
      "name":{
         "TC":"曉光街",
         "EN":"Hiu Kwong Street Sports Centre"
      },
      "region":"KLN",
      "district":"KT"
   },
   "284":{
      "name":{
         "TC":"九龍灣",
         "EN":"Kowloon Bay Sports Centre"
      },
      "region":"KLN",
      "district":"KT"
   },
   "287":{
      "name":{
         "TC":"藍田南",
         "EN":"Lam Tin South Sports Centre"
      },
      "region":"KLN",
      "district":"KT"
   },
   "288":{
      "name":{
         "TC":"牛頭角道",
         "EN":"Ngau Tau Kok Road Sports Centre"
      },
      "region":"KLN",
      "district":"KT"
   },
   "291":{
      "name":{
         "TC":"鯉魚門",
         "EN":"Lei Yue Mun Sports Centre"
      },
      "region":"KLN",
      "district":"KT"
   },
   "292":{
      "name":{
         "TC":"順利邨",
         "EN":"Shun Lee Tsuen Sports Centre"
      },
      "region":"KLN",
      "district":"KT"
   },
   "293":{
      "name":{
         "TC":"瑞和街",
         "EN":"Shui Wo Street Sports Centre"
      },
      "region":"KLN",
      "district":"KT"
   },
   "300":{
      "name":{
         "TC":"港灣道",
         "EN":"Harbour Road Sports Centre"
      },
      "region":"HK",
      "district":"WCH"
   },
   "302":{
      "name":{
         "TC":"駱克道",
         "EN":"Lockhart Road Sports Centre"
      },
      "region":"HK",
      "district":"WCH"
   },
   "303":{
      "name":{
         "TC":"伊利沙伯",
         "EN":"Queen Elizabeth Stadium"
      },
      "region":"HK",
      "district":"WCH"
   },
   "304":{
      "name":{
         "TC":"黃泥涌",
         "EN":"Wong Nai Chung Sports Centre"
      },
      "region":"HK",
      "district":"WCH"
   },
   "305":{
      "name":{
         "TC":"官涌",
         "EN":"Kwun Chung Sports Centre"
      },
      "region":"KLN",
      "district":"YTM"
   },
   "307":{
      "name":{
         "TC":"九龍公園",
         "EN":"Kowloon Park Sports Centre"
      },
      "region":"KLN",
      "district":"YTM"
   },
   "50000512":{
      "name":{
         "TC":"大埔墟",
         "EN":"Tai Po Hui Sports Centre"
      },
      "region":"NTE",
      "district":"TP"
   },
   "60000514":{
      "name":{
         "TC":"馬鞍山",
         "EN":"Ma On Shan Sports Centre"
      },
      "region":"NTE",
      "district":"ST"
   },
   "70000521":{
      "name":{
         "TC":"大角咀",
         "EN":"Tai Kok Tsui Sports Centre"
      },
      "region":"KLN",
      "district":"YTM"
   },
   "70000522":{
      "name":{
         "TC":"赤柱",
         "EN":"Stanley Sports Centre"
      },
      "region":"HK",
      "district":"SN"
   },
   "70000525":{
      "name":{
         "TC":"天瑞",
         "EN":"Tin Shui Sports Centre"
      },
      "region":"NTW",
      "district":"YL"
   },
   "70000623":{
      "name":{
         "TC":"東涌文東路",
         "EN":"Tung Chung Man Tung Road Sports Centre"
      },
      "district":"IS",
      "region":"NTW"
   },
   "70000727":{
      "name":{
         "TC":"小西灣",
         "EN":"Siu Sai Wan Sports Centre"
      },
      "district":"EN",
      "region":"HK"
   },
   "70000734":{
      "name":{
         "TC":"坑口",
         "EN":"Hang Hau Sports Centre"
      },
      "region":"NTE",
      "district":"SK"
   },
   "70000830":{
      "name":{
         "TC":"保榮路",
         "EN":"Po Wing Road Sports Centre"
      },
      "district":"N",
      "region":"NTE"
   },
   "70000831":{
      "name":{
         "TC":"屏山天水圍",
         "EN":"Ping Shan Tin Shui Wai Sports Centre"
      },
      "region":"NTW",
      "district":"YL"
   },
   "70000931":{
      "name":{
         "TC":"天暉路",
         "EN":"Tin Fai Road Sports Centre"
      },
      "region":"NTW",
      "district":"YL"
   },
   "70001033":{
      "name":{
         "TC":"香港單車館",
         "EN":"Hong Kong Velodrome"
      },
      "region":"NTE",
      "district":"SK"
   },
   "70001333":{
      "name":{
         "TC":"調景嶺",
         "EN":"Tiu Keng Leng Sports Centre"
      },
      "region":"NTE",
      "district":"SK"
   },
   "70001533":{
      "name":{
         "TC":"圓洲角",
         "EN":"Yuen Chau Kok Sports Centre"
      },
      "region":"NTE",
      "district":"ST"
   },
   "70001534":{
      "name":{
         "TC":"元朗",
         "EN":"Yuen Long Sports Centre"
      },
      "region":"NTW",
      "district":"YL"
   },
   "70001535":{
      "name":{
         "TC":"青衣西南",
         "EN":"Tsing Yi Southwest Sports Centre"
      },
      "district":"KWT",
      "region":"NTW"
   },
   "70001733":{
      "name":{
         "TC":"荃灣 - 主場",
         "EN":"Tsuen Wan Sports Centre - Arena (2/F)"
      },
      "region":"NTW",
      "district":"TW"
   },
   "70001734":{
      "name":{
         "TC":"荃灣 - 副場",
         "EN":"Tsuen Wan Sports Centre - Secondary Hall (G/F)"
      },
      "region":"NTW",
      "district":"TW"
   },
   "70001833":{
      "name":{
         "TC":"兆麟體育館",
         "EN":"Siu Lun Sports Centre"
      },
      "region":"NTE",
      "district":"ST"
   },
   "70001933":{
      "name":{
         "TC":"車公廟",
         "EN":"Che Kung Temple Sports Centre"
      },
      "region":"NTE",
      "district":"ST"
   },
   "70002133": {
      "name":{
         "TC":"彩榮路體育館",
         "EN":"Choi Wing Road Stadium"
      },
      "region":"KLN",
      "district":"KT"
   },
   "70002234": {
      "name":{
         "TC":"深水埗體育館",
         "EN":"Sham Shui Po Sports Centre"
      },
      "region":"KLN",
      "district":"SSP"
   }
}