export default {
  KC: '九龍城',
  KT: '官塘',
  WTS: '黃大仙',
  YTM: '油尖旺',
  SSP: '深水埗',
  WCH: '灣仔',
  EN: '東區',
  SN: '南區',
  CW: '中西區',
  SK: '西貢',
  ST: '沙田區',
  N: '北區',
  TP: '大埔區',
  TM: '屯門區',
  YL: '元朗區',
  IS: '離島區',
  TW: '荃灣區',
  KWT: '葵青區'
}
